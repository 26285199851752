import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
// import Orbital from '../components/orbital';

export const query = graphql`
    query($id: String!) {
        prismic {
            page(lang: "en-us", uid: $id) {
                description
                title
                products {
                    angle
                    distance
                    product {
                        ... on PRISMIC_Product {
                            _meta {
                                uid
                            }
                            image
                            title
                            summary
                            tags {
                                angle
                                distance
                                tag {
                                    ... on PRISMIC_Tag {
                                        name
                                        _meta {
                                            uid
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const Page = ({ data }) => {
    // Required check for no data being returned
    const doc = data.prismic.page;
    if (!doc) {
        return null;
    }
    const title = doc.title;

    return (
        <Layout>
            <SEO title={title} />
            {/* <Orbital withTitle edges={doc.products} /> */}
        </Layout>
    );
};

Page.propTypes = {
    data: PropTypes.object.isRequired,
};

export default Page;
